import React, { useState, useEffect } from "react";
import { Button, Modal, Form, FormGroup, FormLabel, } from "react-bootstrap";
import axios from 'axios';
import { useNavigate } from 'react-router-dom';
import { makeApiCall, UrlApi, TokenExpirado, Refresh } from '../../Utils/Autenticacao_API';
import DatePicker from "react-datepicker";

const VendasAnualRelatorio = ({ showModalProp, onCloseModal }) => {
    const navigate = useNavigate();
    const [isLoading, setIsLoading] = useState(true);

    const [ano, setAno] = useState('');

    const [lojas, setLojas] = useState([])
    const [lojaId, setLojaId] = useState('')
    const [lojaSelecionada, setLojaSelecionada] = useState("")

    useEffect(() => {
        const fetchData = async () => {
            try {

                const lojasResponse = makeApiCall('lojas',);

                const [lojasData] = await Promise.all([
                    lojasResponse,

                ]);


                setLojas(lojasData.data)


            } catch (error) {
                console.error(error);
                const tokenExpirado = TokenExpirado();
                if (tokenExpirado === 'refreshToken' || tokenExpirado === false) {
                    navigate('/login');
                } else if (tokenExpirado === 'acessToken') {
                    Refresh();
                }
            } finally {
                setIsLoading(false);
            }
        };

        fetchData();
    }, [navigate]);

    const handleGenerateReport = async () => {




        if (!ano) {
            return;
        }

        setIsLoading(true)


        const accessToken = localStorage.getItem('accessToken');
        try {
            console.log(ano)
            const response = await axios.get(`${UrlApi}/relatorios/vendas/anual/visualizarRelatorio?ano=${ano}&id_loja=${lojaId}`, {
                headers: {
                    Authorization: `Bearer ${accessToken}`
                },
                responseType: 'blob',
            });

            const file = new Blob([response.data], { type: 'application/pdf' });
            const fileURL = URL.createObjectURL(file);
            window.open(fileURL);

        } catch (error) {
            console.error(error);
            const tokenExpirado = TokenExpirado();
            if (tokenExpirado === "refreshToken" || tokenExpirado === false) {
                navigate('/login');
            } else if (tokenExpirado === 'acessToken') {
                try {
                    const refres = await Refresh();
                    if (refres === 'login') {
                        navigate('/login');
                    }
                } catch (error) {
                    console.log("ERRO NA CHAMADA DA API");
                }
            }
        } finally {
            setIsLoading(false)
        }
    };



    const handleCloseModal = () => {

        setAno("");

        setLojaId('')
        setLojaSelecionada('')
        onCloseModal();
    };

    if (isLoading) {
        return <div className="position-fixed top-50 start-50 translate-middle">
            <div className="spinner-grow text-primary" role="status">
                <span className="visually-hidden">Carregando...</span>
            </div>
        </div>;
    }

    return (
        <Modal show={showModalProp} onHide={handleCloseModal}>
            <Modal.Header closeButton>
                <Modal.Title>Gerar Relatório</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <Form>

                    <FormGroup>
                        <FormLabel className='mb-0 mt-2' htmlFor="loja"><strong>Loja</strong></FormLabel>
                        <Form.Control
                            as="select"
                            value={lojaSelecionada}
                            onChange={(event) => {
                                const lojaIndex = event.target.selectedIndex - 1;
                                setLojaSelecionada(event.target.value);

                                if (lojaIndex >= 0) {

                                    setLojaId(lojas[lojaIndex].id);
                                } else {

                                    setLojaId('');
                                }
                            }}
                        >
                            <option value=''>Todas</option>
                            {lojas.map((loja) => (
                                <option key={loja.id} value={loja.razao}>{loja.razao}</option>
                            ))}
                        </Form.Control>
                    </FormGroup>

                    <Form.Group className="mb-3 mt-3" controlId="selectAno">
                        <Form.Label><strong>Selecionar Ano</strong></Form.Label>
                        <br />
                        <DatePicker
                            selected={ano ? new Date(ano, 0, 1) : null} // Define o ano selecionado
                            onChange={(date) => setAno(date ? date.getFullYear() : '')} // Define apenas o ano
                            dateFormat="yyyy" // Mostra apenas o ano
                            showYearPicker // Mostra apenas o seletor de ano
                            className="form-control"
                            locale="ptBR"
                        />
                    </Form.Group>



                </Form>
            </Modal.Body>
            <Modal.Footer>
                <Button variant="secondary" onClick={handleCloseModal}>
                    Cancelar
                </Button>
                <Button className="mt-2" disabled={!ano} variant="primary" onClick={handleGenerateReport}>
                    Gerar Relatório
                </Button>
            </Modal.Footer>
        </Modal>
    );
};

export default VendasAnualRelatorio;
